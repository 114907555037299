import React from 'react'
import symbol from '../assets/symbol.png'
import Dropdown from './header/Dropdown'

function Header({ user }) {
  return (
    <div className='bg-gradient-to-br to-zinc-600 from-zinc-700 h-24 py-2 shadow-md shadow-indigo-600 rounded-t-lg'>
      <div className='flex justify-between'>
      <div className='flex'>
        <img draggable="false" className='h-20 ml-2 drop-shadow-xl' src={symbol} alt="logo"/> 
        <h1 className='mt-6 ml-1 text-2xl'>Korsten Car Service</h1>
      </div>
        <Dropdown user={user}/>
      </div>
    </div>
  )
}

export default Header