import React from 'react';
import Appointments from './Appointments';
import Cars from './Cars';
import Users from '../Users/Users';
import AllCars from '../AllCars/AllCars';

function DashView({ selectedTab, user }) {
  return <>
  {
    (selectedTab === "Home") ?
      <div>
        Welkom bij Korsten Car Service
      </div>
    : <></>
  }
  {
    (selectedTab === "Appointments") ?
      <div>
        <Appointments user={user}/>
      </div>
    : <></>
  }
  {
    (selectedTab === "MyCars") ?
      <div>
        <Cars user={user}/>
      </div>
    : <></>
  }
  {
    (selectedTab === "AllCars") ?
      <div>
        <AllCars user={user}/>
      </div>
    : <></>
  }
  {
    (selectedTab === "Users") ?
      <div>
        <Users/>
      </div>
    : <></>
  }
  </>
  
}

export default DashView