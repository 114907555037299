import React from 'react'
import AllAppointments from '../Appointments/AllAppointments'
import MechanicAppointments from '../Appointments/MechanicAppointments'
import MyAppointments from '../Appointments/MyAppointments'

function Appointments({ user }) {
  return <>
    {
      (user.role === 'manager') ? <AllAppointments user={user}/> : <></>
    }
    {
      (user.role === 'mechanic') ? <MechanicAppointments user={user}/> : <></>
    }
    {
      (user.role === 'customer') ? <MyAppointments user={user}/> : <></>
    }
  </>
  
}

export default Appointments