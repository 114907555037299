import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    if(!localStorage.getItem('mechanic-user')) {
      navigate('/login');
    } else {
      navigate('/dashboard');
    }
  })
}

export default Home