import React from 'react'
import { motion } from 'framer-motion'
import Backdrop from './Backdrop'
import AddCarComponent from '../dashboard/AddCarComponent'
import UpdateRoleModal from './UpdateRoleModal'
import CreateAppointment from './CreateAppointment'
import MechanicCreateAppointment from './MechanicCreateAppointment'

const dropIn = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        },
    },
    exit: {
        y: "100vh",
        opacity: 0,
    },
}

function Modal({ handleClose, type, user, selectedUser, modalOpen, refresh, setRefresh }) {
  return (
    <Backdrop onClick={handleClose}>
        <motion.div
            onClick={(e) => e.stopPropagation()}
            className='modal z-50'
            variants={dropIn}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            {
            (type === "AddCarModal") ?
                <AddCarComponent modalState={modalOpen={}} close={handleClose} user={user}/> 
            : <></>
            }
            {
            (type === "UpdateRoleModal") ?
                <UpdateRoleModal refresh={refresh} setRefresh={setRefresh} modalState={modalOpen={}} close={handleClose} selectedUser={selectedUser} /> 
            : <></>
            }
            {
                (type === "CreateAppointment") ?
                <CreateAppointment user={user} refresh={refresh} setRefresh={setRefresh} update={modalOpen} modalState={modalOpen={}} close={handleClose} /> 
            : <></>
            }
                        {
                (type === "MechanicCreateAppointment") ?
                <MechanicCreateAppointment user={user} refresh={refresh} setRefresh={setRefresh} update={modalOpen} modalState={modalOpen={}} close={handleClose} /> 
            : <></>
            }
        </motion.div>
    </Backdrop>
  )
}

export default Modal