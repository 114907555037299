import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toastOptions } from '../utils/Toast';
import { ToastContainer, toast } from 'react-toastify';
import { loginRoute } from '../utils/APIRoutes';

function Login() {
    useEffect(() => {
        if(localStorage.getItem('mechanic-user')) {
            navigate('/dashboard');
        }
    })

  const navigate = useNavigate();
    const [values, setValues] = useState({
        email: "",
        password: "",
    });

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]:event.target.value })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if(handleValidation()) {
            const formData = new FormData();
            formData.append('email', values.email);
            formData.append('password', values.password);
            // create axios post that logs in the user if 
            // returns success set itemstorage and redirect to '/'
            const {data} = await axios.post(loginRoute, formData)
            if(data.status === false){
                toast.error(data.msg, toastOptions);
            }
            if(data.status === true) {
                localStorage.setItem('mechanic-user', JSON.stringify(data.user));
                navigate('/dashboard');
            } else if (data.status === false && data.msg === undefined) {
                toast.error("Ongeldige gegevens", toastOptions);
            }
        }
    }

    const handleValidation = () => {
        const {password,email} = values;
        let error = "";
        if (email.length < 5) {
          error = "Email moet langer zijn dan 4 karakters";
        } else if (password.length < 8) {
          error = "Wachtwoord moet op zijn minst 8 karakters lang zijn";
        }
    
        if (error !== ""){
          toast.error(error, toastOptions)
          return false;
        }
    
        return true;
      }

  return (
    <div className='h-screen'>
        <div className='grid h-screen place-items-center'>
            <div className='card'>
                <span className='text-3xl mb-4'>Welkom terug!</span>
                <form onSubmit={(event) => handleSubmit(event)} className='flex flex-col mt-2 gap-y-4'>
                    <div className='flex flex-col'>
                        <label className='flex'>Email</label>
                        <input onChange={(e) => handleChange(e)} name="email" type="email" className='mt-1 input'/>
                    </div>

                    <div className='flex flex-col'>
                        <label className='flex'>Wachtwoord</label>
                        <input onChange={(e) => handleChange(e)} name="password" type="password" className='mt-1 input'/>
                    </div>

                    <div className='flex justify-end mr-4 gap-x-2'>
                        <Link to="/register" className='mt-2 transition hover:text-indigo-600'>Heb je nog geen account?</Link>
                        <button type='submit' className='btn btn-primary'>Inloggen</button>
                    </div>
                </form>
            </div>
        </div>
        <ToastContainer/>
    </div>
  )
}

export default Login