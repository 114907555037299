import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faCar, faUsers } from '@fortawesome/free-solid-svg-icons'

function SideNavigation({ user, activeTab, changeLeftTab }) {
  const changeCurrentTab = (tab) => {
    changeLeftTab(tab);
  }

  return <>
  {
    <div className='py-20 pl-2'>
      <div className='flex flex-col gap-y-3'>

        <button onClick={() => changeCurrentTab("Appointments")} className={`navItem ${(activeTab === "Appointments") ? 'bg-indigo-500' : "bg-zinc-800 hover:bg-zinc-900"}`}>
            <FontAwesomeIcon className='mt-1' icon={faCalendarCheck}/>
            <span className='ml-2'>Afspraken</span>
        </button>

        {
          (user.role === "customer") ?
            <button onClick={() => changeCurrentTab("MyCars")} className={`navItem ${(activeTab === "MyCars") ? 'bg-indigo-500' : "bg-zinc-800 hover:bg-zinc-900"}`}>
              <FontAwesomeIcon className='mt-1' icon={faCar}/>
              <span className='ml-2'>Auto's</span>
            </button>
          : <></>
        }
        {
          (user.role === "mechanic") ?
            <button onClick={() => changeCurrentTab("AllCars")} className={`navItem ${(activeTab === "AllCars") ? 'bg-indigo-500' : "bg-zinc-800 hover:bg-zinc-900"}`}>
              <FontAwesomeIcon className='mt-1' icon={faCar}/>
              <span className='ml-2'>Alle Auto's</span>
            </button>
          : <></>
        }

        {
          (user.role === "manager") ?
            <>
            <button onClick={() => changeCurrentTab("AllCars")} className={`navItem ${(activeTab === "AllCars") ? 'bg-indigo-500' : "bg-zinc-800 hover:bg-zinc-900"}`}>
              <FontAwesomeIcon className='mt-1' icon={faCar}/>
              <span className='ml-2'>Alle Auto's</span>
            </button>

              <button onClick={() => changeCurrentTab("Users")} className={`navItem ${(activeTab === "Users") ? 'bg-indigo-500' : "bg-zinc-800 hover:bg-zinc-900"}`}>
              <FontAwesomeIcon className='mt-1' icon={faUsers}/>
              <span className='ml-2'>Accounts</span>
              </button>
            </>
          : <></>
        }
      </div>
    </div>
  }
  </>
  
}

export default SideNavigation