import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header'
import SideNavigation from '../components/SideNavigation'
import Loader from '../components/Loader'
import DashView from '../components/dashboard/DashView';

function Dashboard() {
    const navigate = useNavigate();
    const [user, setUser] = useState(undefined);
    const [isLoaded, setIsLoaded] = useState(false); 
    const [leftNavTab, setLeftNavTab] = useState("Appointments");

    useEffect(() => {
      loadUser();
    }, [])

    const loadUser = async () => {
      if(!localStorage.getItem("mechanic-user")) {
        navigate("/login");
      }
  
      setUser(await JSON.parse(localStorage.getItem("mechanic-user")));
      setIsLoaded(true);
    }

    const handleNavUpdate = (tab) =>{
      setLeftNavTab(tab);
    }

  return <>
  {
    // ADD NAVIGATION TO THIS PAGE SO WE CAN SET THE STATE AND GIVE IT TO THE DASHVIEW
    isLoaded ? (
      <div className='flex justify-center h-screen p-10 overflow-y-hidden'>
        <div className='bg-gradient-to-br h-[90vh] from-zinc-600 to-zinc-800 rounded-lg w-full border-2 shadow-md shadow-indigo-700 border-indigo-600'>
          <Header user={user}/>
          <div className='flex py-4 h-[75vh] mt-4 gap-x-4'>
            <SideNavigation user={user} activeTab={leftNavTab} changeLeftTab={handleNavUpdate}/>
            <div className='py-4 flex-1 h-full overflow-y-auto flex-col mr-4 shadow-md shadow-primary border-2 border-primary bg-gradient-to-tr from-zinc-600 to-zinc-700 flex col-span-9 rounded-xl'>
              <div className='px-4'>
                <DashView user={user} selectedTab={leftNavTab}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <Loader/>
    )
  }
  </>
}

export default Dashboard