import React from 'react'

function Loader() {
  return (
    <div className='grid h-screen place-items-center'>
      <div className="w-12 h-12 rounded-full animate-spin border-8 border-solid border-indigo-600 border-t-transparent"></div>
    </div>
  )
}

export default Loader