import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import UserDataTable from '../datatables/UserDataTable'

function Users() {
    return (
        <div>
          <div className='flex justify-between mb-4'>
            <div>
              <h1 className='text-3xl'><FontAwesomeIcon className='text-indigo-400' icon={faUsers}/> Alle Accounts</h1>
            </div>
          </div>
          <UserDataTable/>
        </div>
      )
}

export default Users