import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { retrieveAllCarsRoute, unlinkCarRoute } from '../../utils/APIRoutes';
import CarServiceHistory from '../CarServiceHistory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const ExpandedComponent = ({ data }) => 
<div>
    <CarServiceHistory car={data}/>
</div>;

function AllCarsDataTable({ user, update }) {
    const [cars, setCars] = useState(undefined);
    const [refresh, setRefresh] = useState(false);

    const columns = [
        {
            name: 'Merk',
            selector: row => row.brandName,
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
        },
        {
            name: 'Eigenaar',
            selector: row => row.customerName,
            sortable: true,
        },
        {
            name: 'Kleur',
            selector: row => row.color,
            sortable: true,
        },
        {
            name: 'Kenteken',
            selector: row => row.license_plate,
            sortable: true,
        },
        {
            cell:(row) => <button onClick={() => clickHandler(row.id)} id={row.id}><FontAwesomeIcon className='text-gray-300 hover:text-red-500 transition' icon={faTrash}/></button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    
    const clickHandler = async (id) => {
        const formData = new FormData();
        formData.append('id', id)
        const {data} = await axios.post(unlinkCarRoute, formData);
        if(data.status){
            setRefresh(!refresh);
        }
    }

    useEffect(() => {
        retrieveMyCars();
    }, [])

    useEffect(() => {
        retrieveMyCars();
    }, [update, refresh])

    const retrieveMyCars = async () => {
        const {data} = await axios.get(retrieveAllCarsRoute)
        if(data.status){
            setCars(data.cars)
        }
    }

    return (
        <>
            <div className='rounded-t-xl shadow shadow-indigo-500'>

                <DataTable
                columns={columns}
                data={cars}
                pagination
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                theme='dark'
                />
            </div>
        </>
    )
}

export default AllCarsDataTable