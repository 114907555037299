import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons'
import { motion } from 'framer-motion'
import Modal from '../modal/Modal'
import AllAppointmentsDataTable from '../datatables/AllAppointmentsDataTable'

function AllAppointments({user}) {
    const [modalOpen, setModalOpen] = useState(false);

    const close = () => setModalOpen(false);
    const open = () => setModalOpen(true);

    return (
        <div>
          <div className='flex justify-between mb-4'>
            <div>
              <h1 className='text-3xl'><FontAwesomeIcon className='text-indigo-400' icon={faCalendarCheck}/> Alle afspraken</h1>
            </div>
            <motion.button 
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className='btn btn-primary'
              onClick={() => (modalOpen ? close() : open())}
              >
                Afspraak maken
              </motion.button>
            
              {modalOpen && <Modal type={"CreateAppointment"} modalOpen={modalOpen} user={user} handleClose={close}/>}      
          </div>
          <AllAppointmentsDataTable update={modalOpen}/>
        </div>
      )
}

export default AllAppointments