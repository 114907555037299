import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toastOptions } from '../../utils/Toast';
import { ToastContainer, toast } from 'react-toastify';
import { registerCarRoute, searchCarOnLicensePlateRoute } from '../../utils/APIRoutes';

function AddCarComponent({ user, close, modalState }) {
  const [values, setValues] = useState({
    brand: "",
    type: "",
    color: "",
    license_plate: "",
  });

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleChange = (event) => {
    if(event.target.name === "license_plate"){
      setValues({ ...values, [event.target.name]:event.target.value.toUpperCase() })
    }else {
      setValues({ ...values, [event.target.name]:event.target.value })
    }
  }

  useEffect(() => {
    getLicensePlateData();
  }, [values.license_plate])

  const getLicensePlateData = async () => {
    let licenseplate = values.license_plate.replace('-', '');
    const {data} = await axios.get(`${searchCarOnLicensePlateRoute}${licenseplate.toUpperCase().replace('-', '')}`)
    if(data.length > 0)
    {
      setValues({...values, 
        'brand':  capitalizeFirstLetter(data[0].merk.toLowerCase()), 
        'type': capitalizeFirstLetter(data[0].handelsbenaming.toLowerCase()), 
        'color': capitalizeFirstLetter(data[0].eerste_kleur.toLowerCase())});
    } else {
      setValues({...values, 'brand': "", 'type': "", 'color': ""});
    }

  }

  const addCar = async () => {
    if(handleValidation)
    {
      const {brand,type,color,license_plate} = values;
      const formData = new FormData()
      formData.append('brand', brand)
      formData.append('type', type)
      formData.append('color', color)
      formData.append('license_plate', license_plate)
      formData.append('user_id', user.id)

      const {data} = await axios.post(registerCarRoute, formData)

      if(data.status){
        modalState = close();
      }
    }
  }

  const handleValidation = () => {
    const {license_plate, brand} = values;
    let error = "";
    if (license_plate.length < 1) {
      error = "Vul een kenteken in";
    } else if(brand.length < 1){
      error = "Vul een geldig kenteken in";
    }

    if (error !== ""){
      toast.error(error, toastOptions)
      return false;
    }

    return true;
  }

  return (
    <div className='flex justify-center flex-col pt-4'>
      <div className='text-2xl text-center mb-2'>Een auto toevoegen</div>
      <div className='flex flex-col gap-y-4'>

      <div className='flex justify-center gap-x-2'>
          <span className='mt-2'>Kenteken</span>
          <input name='license_plate' value={values.license_plate} onChange={(e) => handleChange(e)} className='input' type="text"/>
        </div>

      <div className='flex justify-center gap-x-2'>
          <span className='mt-2'>Merk</span>
          <input onChange={(e) => handleChange(e)} disabled value={values.brand} name='brand' className='input disabled' type="text"/>
        </div>

        <div className='flex justify-center gap-x-2'>
          <span className='mt-2'>Type</span>
          <input onChange={(e) => handleChange(e)} disabled value={values.type} name='type' className='input disabled' type="text"/>
        </div>

        <div className='flex justify-center gap-x-2'>
          <span className='mt-2'>Kleur</span>
          <input onChange={(e) => handleChange(e)} disabled value={values.color} name='color' className='input disabled' type="text"/>
        </div>

        <div className='flex justify-end gap-x-2 mb-2'>
          <button className='btn btn-primary' onClick={close}>Annuleren</button>
          <button className='btn btn-primary' onClick={addCar}>Toevoegen</button>
        </div>
      </div>
      <ToastContainer/>
    </div>
  )
}

export default AddCarComponent