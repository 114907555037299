import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { getAllUsersRoute } from '../../utils/APIRoutes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import Modal from '../modal/Modal'

function UserDataTable({ user, update }) {
    const [users, setUsers] = useState(undefined);
    const [refresh, setRefresh] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState();

    const close = () => setModalOpen(false);
    const open = () => setModalOpen(true);

    const columns = [
        {
            name: 'Naam',
            selector: row => row.firstname + " " + row.lastname,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Rol',
            selector: row => row.role,
            sortable: true,
        },
        {
            cell:(row) => <button onClick={() => clickHandler(row)} id={row.id}><FontAwesomeIcon className='text-gray-300 hover:text-yellow-400 transition' icon={faPencil}/></button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          },
    ];
    
    const clickHandler = async (row) => {
        setSelectedUser(row);
        modalOpen ? close() : open();
    }

    useEffect(() => {
        retrieveUsers();
    }, [])

    useEffect(() => {
        retrieveUsers();
    }, [update, refresh])

    const retrieveUsers = async () => {
        const {data} = await axios.get(getAllUsersRoute)
        if(data.status){
            setUsers(data.users)
        }
    }

  return (
    <>
        <div className='rounded-t-xl shadow shadow-indigo-500'>
            <DataTable
            columns={columns}
            data={users}
            pagination
            theme='dark'
            />

            {modalOpen && <Modal refresh={refresh} setRefresh={setRefresh} type={"UpdateRoleModal"} selectedUser={selectedUser} modalOpen={modalOpen} handleClose={close}/>}      
        </div>
    </>
  )
}

export default UserDataTable