import React, { useState } from 'react'
import CarDataTable from '../datatables/CarDataTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar } from '@fortawesome/free-solid-svg-icons'
import { motion } from 'framer-motion'
import Modal from '../modal/Modal'

function Cars({ user }) {
  const [modalOpen, setModalOpen] = useState(false);

  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  return (
    <div>
      <div className='flex justify-between mb-4'>
        <div>
          <h1 className='text-3xl'><FontAwesomeIcon className='text-indigo-400' icon={faCar}/> Mijn auto(s)</h1>
        </div>
        <motion.button 
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className='btn btn-primary'
          onClick={() => (modalOpen ? close() : open())}
          >
            Auto toevoegen
          </motion.button>

          {modalOpen && <Modal type={"AddCarModal"} user={user} modalOpen={modalOpen} handleClose={close}/>}      
      </div>
      <CarDataTable update={modalOpen} user={user}/>
    </div>
  )
}

export default Cars