import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';

function Dropdown({  user }) {
    const [showOptions, setShowOptions] = useState(false);
    const navigate = useNavigate();
    const handleClick = () => {
        setShowOptions(!showOptions);
    }

    const handleSignOut = () => {
        localStorage.removeItem('mechanic-user');
        navigate('/');
        
    }

  return (
    <div className='flex flex-col gap-y-6'>
        <div className='mt-5 py-2 px-4 mr-12 flex gap-x-2 cursor-pointer bg-zinc-700 transition hover:bg-zinc-900 rounded-xl' onClick={handleClick}>
            <span>{user.firstname} {user.lastname}</span>
            <FontAwesomeIcon className='mt-1 rounded-full' icon={faUser}/>
        </div>
        {
            showOptions ?
            <div className="z-10 w-36 rounded-md bg-zinc-600 shadow shadow-indigo-600 mt-[-1em] border-2 border-indigo-600 ring-1 ring-black ring-opacity-5 focus:outline-none hover:bg-zinc-900" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
            <div className="py-1" role="none">
                <button onClick={handleSignOut} type="button" className="text-white block w-auto px-4 py-2 text-left text-sm" role="menuitem" tabIndex="-1" id="menu-item">Uitloggen</button>
            </div>
            </div>
            : ""
        }
    </div>

  )
}

export default Dropdown