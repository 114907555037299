import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { createAppointmentRoute, getAllCustomersRoute, mechanicCreateAppointmentRoute, retrieveMyCarsRoute } from '../../utils/APIRoutes';

function CreateAppointment({ close, modalState, setRefresh, refresh, user }) {
    const [customers, setCustomers] = useState(undefined);
    const [selectedCustomer, setSelectedCustomer] = useState(undefined);
    const [cars, setCars] = useState(undefined);
    const [values, setValues] = useState({
        when: null,
        car_id: null,
    });

    useEffect(() => {
        retrieveCustomers();
    }, [])

    useEffect(() => {
        retrieveCars();
    }, [selectedCustomer])

  const retrieveCustomers = async () => {
    const {data} = await axios.get(getAllCustomersRoute)
    setCustomers(data.customers);
  }

  const retrieveCars = async () => {
    const formData = new FormData();
    formData.append('user_id', selectedCustomer)
    const {data} = await axios.post(retrieveMyCarsRoute, formData)
    setCars(data.cars)
  }

  const handleChange = (event) => {
      setValues({ ...values, [event.target.name]:event.target.value })
  }

const saveAppointment = async () => {
    const {when, car_id, description} = values;
    if(when === null || car_id === null || description === ""){
      return;
    }
    console.log(when)
    console.log(car_id)
    console.log(description)
    const formData = new FormData()
    formData.append('when', when)
    formData.append('car_id', car_id);
    formData.append('description', description);
    formData.append('mechanic_id', user.id);

    const {data} = await axios.post(mechanicCreateAppointmentRoute, formData)

    if(data.status){
    modalState = close();
    setRefresh(!refresh);
    }
}

  return (
    <div className='flex justify-center flex-col pt-4'>
      <div className='text-2xl text-center mb-2'>Een afspraak maken</div>
      <div className='flex flex-col gap-y-10 mt-10'>

        <div className='flex justify-center gap-x-2'>
          <span className='mt-2'>Wanneer</span>
          <input type="date" onChange={(e) => handleChange(e)} className='input' name="when"/>
        </div>

        <div className='flex justify-center gap-x-2'>
          <span className='mt-2'>Klant</span>
          <select onChange={(e) => setSelectedCustomer(e.target.value)} name="customer_id" className='input'>
            <option value={null}>Maak een keuze</option>
            {
              // loop through all customers
              (customers !== undefined)?
              customers.map((data) => (
                <option key={data.id} value={data.id}>{data.firstname} {data.lastname}</option>
              )): <></>
            }
          </select>
        </div>

        <div className='flex justify-center gap-x-2'>
          <span className='mt-2'>Auto</span>
          <select onChange={(e) => handleChange(e)} name="car_id" className='input'>
            <option value={null}>Maak een keuze</option>
            {
              // loop through all cars
              (cars !== undefined)?
              cars.map((data) => (
                <option key={data.id} value={data.id}>{data.type}</option>
              )): <></>
            }
          </select>
        </div>

        <div className='flex justify-center gap-x-2'>
          <span className='mt-2'>Omschrijving</span>
          <textarea onChange={(e) => handleChange(e)} className='input' name="description"/>
        </div>

        <div className='flex justify-end gap-x-2 mb-2'>
          <button className='btn btn-primary' onClick={close}>Annuleren</button>
          <button className='btn btn-primary' onClick={saveAppointment}>Afspraak maken</button>
        </div>
      </div>
    </div>
  )
}

export default CreateAppointment