import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { getMyAppointmentsRoute } from '../../utils/APIRoutes';

function MyAppointmentsDataTable({ user, update }) {
    const [appointments, setAppointments] = useState(undefined);
    const [refresh, setRefresh] = useState(false);

    const columns = [
        {
            name: 'Wanneer',
            selector: row => row.when,
            sortable: true,
        },
        {
            name: 'Wat',
            selector: row => row.description,
            sortable: true,
        },
        {
            name: 'Monteur',
            selector: row => row.mechanicName,
            sortable: true,
        },
        {
            name: 'Auto',
            selector: row => row.car,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.finished,
            sortable: true,
        },
    ];


    useEffect(() => {
        retrieveAppointments();
    }, [])

    useEffect(() => {
        retrieveAppointments();
    }, [update, refresh])

    const retrieveAppointments = async () => {
        const formData = new FormData();
        formData.append('id', user.id);
        const {data} = await axios.post(getMyAppointmentsRoute, formData)
        console.log(data.appointments);
        if(data.status){
            setAppointments(data.appointments)
        }
    }

  return (
    <>
        <div className='rounded-t-xl shadow shadow-indigo-500'>
            <DataTable
            columns={columns}
            data={appointments}
            pagination
            theme='dark'
            />
        </div>
    </>
  )
}

export default MyAppointmentsDataTable