export const host = "https://backend.voertuigen.joriskorsten.nl";

// Auth
export const registerRoute = `${host}/api/auth/register`;
export const loginRoute = `${host}/api/auth/login`;

// Cars - AllCars
export const retrieveMyCarsRoute = `${host}/api/mycars/get`;
export const retrieveCarAppointmentsHistory = `${host}/api/appointments/getcarappointments/`
export const getCarBrandsRoute = `${host}/api/cars/get-brands`;
export const searchCarOnLicensePlateRoute = `https://opendata.rdw.nl/resource/m9d7-ebf2.json?kenteken=`;
export const registerCarRoute = `${host}/api/mycars/register`;
export const unlinkCarRoute = `${host}/api/mycars/delete`;
export const retrieveAllCarsRoute = `${host}/api/cars/get`;

// Users
export const getAllUsersRoute = `${host}/api/users/get`;
export const updateUserRoleRoute = `${host}/api/users/update-user-role`;

// Appointments
export const getMyAppointmentsRoute = `${host}/api/appointments/my-appointments`;
export const getMechanicAppointmentsRoute = `${host}/api/appointments/mechanic-appointments`;
export const getAllCustomersRoute = `${host}/api/appointments/get-customers`;
export const getAllAppointmentsRoute = `${host}/api/appointments/all-appointments`;
export const cancelAppointmentRoute = `${host}/api/appointments/cancel-appointment`;
export const markAppointmentAsDoneRoute = `${host}/api/appointments/mark-as-done`;
export const createAppointmentRoute = `${host}/api/appointments/create`;
export const mechanicCreateAppointmentRoute = `${host}/api/appointments/mechanic-create`;
