import axios from 'axios';
import React, { useState } from 'react'
import { toastOptions } from '../../utils/Toast';
import { ToastContainer, toast } from 'react-toastify';
import { updateUserRoleRoute } from '../../utils/APIRoutes';

function UpdateRoleModal({ selectedUser, close, modalState, setRefresh, refresh }) {
  const [values, setValues] = useState({
    role: null,
  });

  const handleChange = (event) => {
      setValues({ ...values, [event.target.name]:event.target.value })
  }

const updateRole = async () => {
    const {role} = values;
    if(role === null){
    return;
    }
    const formData = new FormData()
    formData.append('role', role)
    formData.append('id', selectedUser.id)

    const {data} = await axios.post(updateUserRoleRoute, formData)

    if(data.status){
    modalState = close();
    setRefresh(!refresh);
    }
}

  return (
    <div className='flex justify-center flex-col pt-4'>
      <div className='text-2xl text-center mb-2'>Rol van {selectedUser.firstname + " " + selectedUser.lastname} wijzigen</div>
      <div className='flex flex-col gap-y-10 mt-10'>

      <div className='flex justify-center gap-x-2'>
          <span className='mt-2'>Role</span>
          <select onChange={(e) => handleChange(e)} name="role" className='input'>
            <option value={null}>Maak een keuze</option>
            <option value="customer">customer</option>
            <option value="mechanic">mechanic</option>
            <option value="manager">manager</option>
          </select>
        </div>

        <div className='flex justify-end gap-x-2 mb-2'>
          <button className='btn btn-primary' onClick={close}>Annuleren</button>
          <button className='btn btn-primary' onClick={updateRole}>Aanpassen</button>
        </div>
      </div>
      <ToastContainer/>
    </div>
  )
}

export default UpdateRoleModal