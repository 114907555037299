import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { retrieveCarAppointmentsHistory } from '../utils/APIRoutes'

function CarServiceHistory({ car }) {
  const [history, setHistory] = useState("");

    useEffect(() => {
        retrieveAppointmentData();
    }, [])

    const retrieveAppointmentData = async () => {
        const {data} = await axios.get(`${retrieveCarAppointmentsHistory}${car.id}`)
        if(data.status === true && data.appointments !== null){
          setHistory(data.appointments);
        }
    }

  return <div className=''>
    <table className='w-full text-sm text-left text-gray-400'>
      <thead className='text-xs uppercase bg-zinc-800 text-gray-400'>
        <tr>
          <th scope='col' className='px-6 py-3'>Datum</th>
          <th scope='col' className='px-6 py-3'>Omschrijving</th>
          <th scope='col' className='px-6 py-3'>Monteur</th>
          <th scope='col' className='px-6 py-3'>Status</th>
        </tr>
      </thead>
      <tbody>
        {
          (history === "") ? <tr><th scope='row' className='px-6 py-4 font-medium text-indigo-400 whitespace-nowrap'>Auto heeft nog geen afspraken</th><td></td><td></td><td></td></tr> :

          history.map(row =>
            <tr className='border-b bg-zinc-700 border-indigo-500' key={row.id}>
              <th scope='row' className='px-6 py-4 font-medium text-indigo-400 whitespace-nowrap'>{row.when}</th>
              <td class="px-6 py-4">{row.description}</td>
              <td class="px-6 py-4">{row.mechanicName}</td>
              <td class="px-6 py-4">{row.finished}</td>
            </tr>  
          )
        }
      </tbody>
    </table>
  </div>
}

export default CarServiceHistory