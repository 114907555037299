import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import { registerRoute } from '../utils/APIRoutes';
import { toastOptions } from '../utils/Toast';

function Register() {
    useEffect(() => {
        if(localStorage.getItem('mechanic-user')) {
            navigate('/dashboard');
        }
    })

    const navigate = useNavigate();
    const [values, setValues] = useState({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        confirm_password: "",
        phone: "",
      });

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]:event.target.value })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if(handleValidation()) {
            const formData = new FormData();
            formData.append('firstname', values.firstname);
            formData.append('lastname', values.lastname);
            formData.append('email', values.email);
            formData.append('password', values.password);
            formData.append('confirm_password', values.confirm_password);
            // create axios post that logs in the user if 
            // returns success set itemstorage and redirect to '/'
            const {data} = await axios.post(registerRoute, formData)
            if(data.status === false){
                toast.error(data.msg, toastOptions);
            }
            if(data.status === true) {
                localStorage.setItem('mechanic-user', JSON.stringify(data.user));
                navigate('/dashboard');
            }
        }
    }

    const handleValidation = () => {
        const {password,confirm_password,firstname, lastname,email} = values;
        let error = "";
        if(firstname.length<2) {
          error = "Naam moet langer zijn dan 1 karakter";
        } else if (lastname.length<2) {
          error = "Achternaam moet langer zijn dan 1 karakter";
        } else if (email.length < 5) {
          error = "Email moet langer zijn dan 4 karakters";
        } else if (password.length < 8) {
          error = "Wachtwoord moet op zijn minst 8 karakters lang zijn";
        } else if (password !== confirm_password){
          error = "Wachtwoord en herhaling moeten hetzelfde zijn.";
        }
    
        if (error !== ""){
          toast.error(error, toastOptions)
          return false;
        }
    
        return true;
      }

    return (
            <div className='h-screen'>
                <div className='grid h-screen place-items-center'>
                    <div className='card'>
                        <span className='text-3xl mb-4'>Account aanmaken</span>
                        <form onSubmit={(event) => handleSubmit(event)} className='flex flex-col mt-2 gap-y-4'>
                            <div className='flex flex-col'>
                                <label className='flex'>Naam</label>
                                <input onChange={(e) => handleChange(e)} name="firstname" type="text" className='mt-1 input'/>
                            </div>

                            <div className='flex flex-col'>
                                <label className='flex'>Achternaam</label>
                                <input onChange={(e) => handleChange(e)} name="lastname" type="text" className='mt-1 input'/>
                            </div>
        
                            <div className='flex flex-col'>
                                <label className='flex'>E-mail</label>
                                <input onChange={(e) => handleChange(e)} name="email" type="email" className='mt-1 input'/>
                            </div>

                            <div className='flex flex-col'>
                                <label className='flex'>Wachtwoord</label>
                                <input onChange={(e) => handleChange(e)} name="password" type="password" className='mt-1 input'/>
                            </div>

                            <div className='flex flex-col'>
                                <label className='flex'>Herhaal wachtwoord</label>
                                <input onChange={(e) => handleChange(e)} name="confirm_password" type="password" className='mt-1 input'/>
                            </div>
        
                            <div className='flex justify-end mr-4 gap-x-2'>
                                <Link to="/login" className='mt-2 transition hover:text-indigo-600'>Heb je al een account?</Link>
                                <button type='submit' className='btn btn-primary'>Registreren</button>
                            </div>
                        </form>
                    </div>
                </div>
                <ToastContainer/>
            </div>


      )
}

export default Register;