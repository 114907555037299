import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { getAllAppointmentsRoute, markAppointmentAsDoneRoute } from '../../utils/APIRoutes';

function AllAppointmentsDataTable({ user, update }) {
    const [appointments, setAppointments] = useState(undefined);
    const [refresh, setRefresh] = useState(false);

    const columns = [
        {
            name: 'Wanneer',
            selector: row => row.when,
            sortable: true,
        },
        {
            name: 'Wat',
            selector: row => row.description,
            sortable: true,
        },
        {
            name: 'Klant',
            selector: row => row.customerName,
            sortable: true,
        },
        {
            name: 'Auto',
            selector: row => row.carName,
            sortable: true,
        },
        {
            name: 'Monteur',
            selector: row => row.mechanicName,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.finished,
            sortable: true,
        },
    ];
    
    const clickHandler = async (id) => {
        const formData = new FormData();
        formData.append('id', id)
        const {data} = await axios.post(markAppointmentAsDoneRoute, formData);
        if(data.status){
            setRefresh(!refresh);
        }
    }

    useEffect(() => {
        retrieveAppointments();
    }, [])

    useEffect(() => {
        retrieveAppointments();
    }, [update, refresh])

    const retrieveAppointments = async () => {
        const {data} = await axios.get(getAllAppointmentsRoute)
        if(data.status){
            setAppointments(data.appointments)
        }
    }

  return (
    <>
        <div className='rounded-t-xl shadow shadow-indigo-500'>

            <DataTable
            columns={columns}
            data={appointments}
            pagination
            theme='dark'
            />
        </div>
    </>
  )
}

export default AllAppointmentsDataTable